<template>
  <div class="segment header-segment">
    <div class="container">
      <router-link :to="{ name: 'start' }">
        <svg-icon name="tke_logo_rgb_standard_white" class="logo" />
      </router-link>
      <div class="product">
        <div class="brand is-size-6">{{ $t('page.brand') }}</div>
        <div class="service is-size-2">{{ $t('page.service') }}</div>
      </div>
      <div class="toolbar">
        <icon-button
          icon="tke_icon_customize"
          class="is-white"
          v-if="$route.name === 'calculator'"
          @click.native="onClickCustomize">
        </icon-button>
        <icon-button
          icon="tke_icon_menu"
          class="is-white"
          @click.native="onClickMenu">
        </icon-button>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'

export default {
  name: 'header-segment',
  methods: {
    onClickMenu () {
      Bus.fire('global/show-navigation-menu')
    },
    onClickCustomize () {
      Bus.fire('global/show-input-menu')
    }
  }
}
</script>

<style lang="sass">
.header-segment
  position: relative
  width: 100%
  padding: 0.7rem 0
  background-color: $black
  color: $text-invert
  z-index: $z-index-header

  .container
    position: relative
    height: ($toolbar-height-mobile - 1.4rem)
    transition: height .5s ease

    .logo
      position: absolute
      bottom: 15px
      height: 50px
      width: 116px

    .product
      display: none

    .toolbar
      position: absolute
      right: $content-padding-mobile

+tablet

  .header-segment
    padding: 1.5rem 0 1.3rem 0

    .container
      height: 4.4rem
      transition: height .5s ease

      .logo
        height: 54px
        bottom: 0

      .product
        position: absolute
        display: inline
        right: $content-padding-desktop
        bottom: -.2rem

        .brand
          line-height: 1
          text-align: right
          font-family: $family-mono-light
          font-size: $size-5

        .service
          text-align: right
          margin-top: 0.25rem
          line-height: 1
          font-family: $family-mono-light

      .toolbar
        display: none

+desktop

  .header-segment
    padding: 1.9rem 0 1.6rem 0

    .container
      height: 5.5rem

      .product
        font-size: $size-3

</style>
