<template>
  <div class="field radio-input">
    <div class="fieldlabel">{{ $t(label) }}</div>
    <div class="control-frame">
      <div class="control">
        <div
          class="handle"
          :style="{ width: handleSize, left: handleLeft }"
          :class="{ 'error': error }">
        </div>
        <label
          class="radio"
          v-for="option in options"
          :key="option.value"
          :class="{ 'active': option.value === value, 'disabled': option.disabled }">
            <input
              type="radio"
              :name="name"
              v-model="value"
              :value="option.value"
              :disabled="option.disabled"
              @change="onChange">
            {{ $t(option.label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import _findIndex from 'lodash/findIndex'
import _toString from 'lodash/toString'
import Bus from '../../services/EventService'
import InputModel from '../../models/InputModel'

export default {
  name: 'radio-input',
  props: ['name', 'options', 'preset', 'label', 'error'],
  data () {
    return {
      value: null,
      width: 50
    }
  },
  computed: {
    handleSize () {
      return this.width + '%'
    },
    handleLeft () {
      var selected = _findIndex(this.options, (option) => {
        return option.value === this.value
      })
      return (this.width * selected) + '%'
    }
  },
  methods: {
    onChange (event) {
      var Input
      Input = new InputModel(this.name, this.value)
      this.$store.commit('inputValueChanged', Input)
    },

    /**
     * Event when an input changed from outside, before calculation
     * invoked by $store.inputValueChanged()
     * invoked by $store.setInputValues()
     *
     * @param {InputModelCollection} Collection
     */
    onInputValueChanged (Collection) {
      var Input
      if (Collection.has(this.name)) {
        Input = Collection.get(this.name)
        if (!Input.compare(this.value)) {
          this.setValue(Input.value)
        }
      }
    },

    /**
     * Setter for value
     * @param number value
     */
    setValue (value) {
      var i
      for (i = 0; i < this.options.length; i++) {
        if (this.options[i].value === value) {
          this.value = value
          return
        }
      }
    }
  },
  created () {
    var check, i

    // nessecary, because numbers given in this.preset are strings,
    // but the type needs to be the same as in this.options
    check = _toString(this.preset)
    for (i = 0; i < this.options.length; i++) {
      if (_toString(this.options[i].value) === check) {
        this.setValue(this.options[i].value)
        break
      }
    }
    this.width = (100 / this.options.length).toPrecision(2)
    Bus.listen('calc/on-input-value-changed', this.onInputValueChanged)
  }
}
</script>

<style lang="sass">
.radio-input

  .control-frame
    padding: 2px
    background-color: $white
    border: 1px solid $border

    .control
      position: relative
      display: flex
      background-color: $white
      height: 2rem

      .handle
        position: absolute
        display: block
        flex: none
        height: 100%
        background-color: $primary
        transition: left .4s ease

        &.error
          background-color: $red

      label
        position: relative
        display: block
        flex-basis: 0
        flex-grow: 1
        flex-shrink: 1
        margin: 0
        text-align: center
        line-height: 2rem
        font-size: $size-5
        font-family: $family-mono-light
        color: $primary

        input
          position: absolute
          opacity: 0
          filter: alpha(opacity=0)
          visibility: visible

        &.active
          color: $white
          font-size: $size-4
          transition: all .4s ease
          cursor: default

        &.disabled
          color: $grey
          cursor: default

+tablet

  .radio-input

    .control-frame

      .control
        height: 1.5rem

        label
          font-size: $size-7
          line-height: 1.5rem

          &.active
            font-size: $size-6

</style>
