<template>
  <div
    class="overlay-segment"
    :class="{ 'is-visible': isVisible, 'is-dialog': isDialog }"
    @click="onClick">
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'

export default {
  name: 'overlay-segment',
  data () {
    return {
      isVisible: false,
      isDialog: false,
      clickEvent: null
    }
  },
  methods: {
    showMenu (clickEvent) {
      this.isDialog = false
      this.isVisible = true
      this.clickEvent = clickEvent
    },
    showDialog (clickEvent) {
      this.isDialog = true
      this.isVisible = true
      this.clickEvent = clickEvent
    },
    hide () {
      this.isVisible = false
      this.clickEvent = null
    },
    onClick () {
      if (this.clickEvent) {
        Bus.fire(this.clickEvent)
      }
    }
  },
  mounted () {
    Bus.listen('global/show-overlay-menu', this.showMenu)
    Bus.listen('global/show-overlay-dialog', this.showDialog)
    Bus.listen('global/hide-overlay', this.hide)
  }
}
</script>

<style lang="sass">
.overlay-segment
  position: fixed
  display: none
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $black
  opacity: 0

  &.is-visible
    display: block
    opacity: .5
    z-index: $z-index-overlay-menu

  &.is-dialog
    background-color: $white
    z-index: $z-index-overlay-dialog

    &.is-visible
      opacity: 1
</style>
