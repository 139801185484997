<template>
  <div class="field slider-input">
    <div class="fieldlabel">
      {{ $t(label) }}
      <span class="unit" v-if="$te(unit)">({{ $t(unit) }})</span>
      <icon-button
        icon="tke_icon_lock"
        class="lock is-small"
        v-if="lockable"
        :class="{ 'is-inactive': input.disabled }"
        @click.native="onToggleLock">
      </icon-button>
    </div>
    <div class="control">
      <input
        type="text"
        class="input is-large"
        ref="input"
        v-model.lazy.number="input.value"
        @change="onUserChangedInput"
        @focus="onFocus"
        :class="{ 'readonly': input.disabled, 'error': error }"
        :readonly="input.disabled"
        :tabindex="tabindex">
      <icon-button
        icon="tke_icon_step-up"
        class="step step-up is-tiny"
        :class="{ 'is-inactive': input.disabled }"
        @click.native="onStepUp">
      </icon-button>
      <icon-button
        icon="tke_icon_step-down"
        class="step step-down is-tiny"
        :class="{ 'is-inactive': input.disabled }"
        @click.native="onStepDown">
      </icon-button>
    </div>
    <div class="slider">
      <div class="slider-frame">
        <div class="slider-bar">
          <vue-slider
            ref="slider"
            v-model.lazy.number="input.value"
            v-bind="slider"
            :min="input.min"
            :max="input.max"
            @callback="onDragging"
            @drag-end="onDragEnd"
            @click.native="onDragEnd"
            :style="{ left: slider.left }">
          </vue-slider>
        </div>
      </div>
      <div class="slider-measure">
        <span class="tick" v-for="n in 21" :key="n"></span>
      </div>
      <div class="slider-boundaries">
        <span class="boundary min">{{ range.min }}</span>
        <span class="boundary max">{{ range.max }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import _round from 'lodash/round'
import _isObject from 'lodash/isObject'
import { TimelineLite } from 'gsap/TweenMax'
import vueSlider from 'vue-slider-component'
import Bus from '../../services/EventService'
import InputModel from '../../models/InputModel'

export default {
  name: 'slider-input',
  props: [
    'name',
    'label',
    'unit',
    'preset',
    'min',
    'max',
    'step',
    'sliderStep',
    'rangeMin',
    'rangeMax',
    'lockable',
    'tabindex',
    'disabled',
    'error'
  ],
  components: {
    'vue-slider': vueSlider
  },
  data () {
    return {
      tween: null,
      sliding: false,
      inputUpdatePending: false,
      input: {
        value: this.preset,
        last: this.preset,
        onSlide: this.preset,
        min: this.min,
        max: this.max,
        step: this.step,
        disabled: this.disabled
      },
      range: {
        min: this.rangeMin,
        max: this.rangeMax
      },
      slider: {
        interval: this.sliderStep,
        tooltip: false,
        height: 8,
        width: '100%',
        left: '0%', // no an original vue-slider property
        dotSize: 20,
        disabled: this.disabled,
        speed: 0 // transition looks not good when slider min/max is changed
      }
    }
  },
  computed: {},
  methods: {

    /*
    |--------------------------------------------------------------------------
    | Input element events
    |--------------------------------------------------------------------------
    */
    onFocus (event) {
      if (this.disabled) {
        return
      }
      this.$refs.input.select()
    },
    onUserChangedInput (event) {
      if (this.disabled) {
        return
      }
      this.$refs.input.blur()
      this.setLastValue()
      this.setValue(this.input.value)
      this.domInformUser()
      this.emitInputChange(false, 'onUserChangedInput')
    },
    onStepUp (event) {
      if (this.disabled) {
        return
      }
      if (this.input.value === this.input.max) {
        return
      }
      this.input.value += this.input.step
      this.setLastValue()
      this.setValue(this.input.value)
      this.domInformUser()
      this.emitInputChange(false, 'onStepUp')
    },
    onStepDown (event) {
      if (this.disabled) {
        return
      }
      if (this.input.value === this.input.min) {
        return
      }
      this.input.value -= this.input.step
      this.setLastValue()
      this.setValue(this.input.value)
      this.domInformUser()
      this.emitInputChange(false, 'onStepDown')
    },
    onToggleLock (event) {
      if (this.disabled) {
        return
      }
      if (this.lockable) {
        this.setLock(!this.input.disabled)
        this.emitInputLock()
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Slider events
    |--------------------------------------------------------------------------
    */

    /**
    * It's not possible to work with drag-start and drag-end events of the
    * slider, because when just clicking on the bar next to the dot, the
    * slider will change it's value, but won't emit those events. So drag-end is
    * faked with click-event (of the dot). On the other hand moving the mouse over the
    * min-max-position of the slider, the click-event is not fired, but
    * the drag-end-event ... Solution: use click and drag-end both and prevent
    * double execution with status this.sliding.
    */
    onDragging (event) {
      if (this.disabled) {
        return
      }
      if (!this.sliding) {
        this.sliding = true
        this.setLastValue()
        this.setOnSlideValue()
        Bus.fire('calc/on-slide-start') // set visible panel to first one
      }
      this.emitInputChange(true, 'onDragging')
    },
    onDragEnd (event) {
      if (this.disabled) {
        return
      }
      if (this.sliding) {
        this.setLastValue()
        this.emitInputChange(false, 'onDragEnd')
        this.sliding = false
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Handle changes from outside
    |--------------------------------------------------------------------------
    |
    | These events are needed in case THIS input value changes, because user
    | changed ANOTHER input
    */

    /**
     * input must be changed after the props of the current door
     *
     * @param {InputModel} Input, can be null if no other value is locked
     */
    onAfterDoorChanged (Input) {
      this.$nextTick(() => {
        if (this.error) {
          this.inputUpdatePending = true
          return
        }
        this.inputUpdatePending = false
        this.setLastValue()
        if (Input && Input.is(this.name)) {
          this.setMin(Input.min)
          this.setMax(Input.max)
        } else {
          this.setMin(this.min)
          this.setMax(this.max)
        }
        this.setStep(this.step)
        this.setSliderStep(this.sliderStep)
        this.setValue(this.input.value, this.input.min, this.input.max, this.input.step)
        if (this.getRange()) {
          this.domUpdateSlider()
        } else {
          this.setLock(true)
        }
        this.domInformUser()
      })
    },

    /**
     * input must be changed after the props of the current door
     *
     * @param {InputModel} Input, can be null if no other value is locked
     */
    onAfterCalculation (Input) {
      if (this.inputUpdatePending) {
        this.onAfterDoorChanged(Input)
      }
    },

    /**
     * Event when an input changed from outside, before calculation
     * invoked by $store.inputValueChanged()
     * invoked by $store.setInputValues()
     *
     * @param {InputModelCollection} Collection
     */
    onInputValueChanged (Collection) {
      var Input
      if (Collection.has(this.name)) {
        Input = Collection.get(this.name)
        if (!Input.compare(this.input.value)) {
          if (!this.disabled && this.input.disabled) {
            this.setLock(false)
            this.emitInputLock()
          }
          this.setValue(Input.value)
          this.setOnSlideValue()
        }
      }
    },

    /**
     * Event when an input changed from outside, after calculation
     * must be separated from onInputValueChanged(), because that function is permanently invoked
     * while sliding, where as this only invoked after sliding ended.
     *
     * invoked by $store.inputValueChanged()
     * invoked by $store.setInputValues()
     */
    onAfterInputValueChanged () {
      this.domInformUser()

      // changing locked values usually results in unlocking the input, but in case of always
      // locked inputs it happens when the user selects an other se
      if (this.disabled) {
        this.emitInputLock()
      }
    },

    /**
     * Min or max changed, after ANOTHER input was set to locked
     *
     * @param {InputModel} Input
     */
    onSetLockDependent (Input) {
      if (Input.is(this.name)) {
        this.setMin(Input.min)
        this.setMax(Input.max)
        if (this.getRange()) {
          this.setLock(false)
          this.domUpdateSlider()
        } else {
          this.setLock(true)
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Getter / Setter
    |--------------------------------------------------------------------------
    */

    /**
     * Setter for value
     * @param number value
     */
    setValue (value, min, max, step) {
      min = min || this.input.min
      max = max || this.input.max
      step = step || this.input.step
      this.input.value = fn.sanitize(value, min, max, step, 'auto')
    },

    setLastValue () {
      this.input.last = this.input.value
    },

    setOnSlideValue () {
      this.input.onSlide = this.input.value
    },

    setMin (min) {
      this.input.min = min
    },

    setMax (max) {
      this.input.max = max
    },

    setStep (step) {
      this.input.step = step
    },

    setSliderStep (step) {
      this.slider.interval = step
    },

    setLock (locked) {

      // don't allow to set to unlocked, when global prop this.disabled is true
      if (this.disabled && !locked) {
        return
      }
      this.input.disabled = locked
      this.slider.disabled = locked
    },

    getRange () {
      return this.input.max - this.input.min
    },

    /*
    |--------------------------------------------------------------------------
    | Emit to store
    |--------------------------------------------------------------------------
    */

    /**
     * sanitize value and then
     * notify store about input change to start calculation
     * @param bool slider
     */
    emitInputChange (slider, action) {
      var Input

      // debugging
      // console.log(action + ' ' + this.name)

      // check how the value has changed when sliding
      Input = new InputModel(this.name, this.input.value)
      if (slider) {
        Input.setSlider()
        if (this.input.value < this.input.onSlide) {
          Input.setChangedDown()
        } else if (this.input.value > this.input.onSlide) {
          Input.setChangedUp()
        }
        this.setOnSlideValue()
      }

      // notify
      this.$store.commit('inputValueChanged', Input)
    },

    /**
     * Invoked on lock/unlock
     */
    emitInputLock () {
      var Input
      Input = new InputModel(this.name, this.input.value)
      if (this.input.disabled) {
        Input.setLocked()
      } else {
        Input.setUnlocked()
      }
      this.$store.commit('setLockDependent', Input)
    },

    /*
    |--------------------------------------------------------------------------
    | Interface
    |--------------------------------------------------------------------------
    */

    /**
    * setting min/max and position of the slider
    */
    domUpdateSlider () {
      var rangeTotal, rangeCurrent, rangeLeft, sliderWidth, sliderLeft, i

      // calculate slider width an position
      rangeTotal = this.range.max - this.range.min
      rangeCurrent = this.input.max - this.input.min
      // this.slider.interval = this.sliderStep
      rangeLeft = this.input.min - this.range.min
      sliderLeft = _round(rangeLeft * 100 / rangeTotal, 0)
      sliderWidth = _round(rangeCurrent * 100 / rangeTotal, 0)
      if (sliderLeft + sliderWidth > 100) {
        sliderWidth = 100 + sliderLeft
      }

      // calculate step
      // Min and max must both be multiple of step, otherwise the slider
      // throws an error. So here we check if this.sliderStep fulfills this
      // or otherwise the highest value smaller than sliderStep is computed
      for (i = this.slider.interval; i > 0; i--) {
        if (this.input.min % i === 0 && this.input.max % i === 0) {
          this.setSliderStep(i)
          break
        }
      }

      // update slider
      this.slider.width = sliderWidth + '%'
      this.slider.left = sliderLeft + '%'
      this.$nextTick(() => {
        if (this.$refs.slider) {
          this.$refs.slider.refresh()
        }
      })
    },

    /**
     * Blink to inform user about automatic input change
     * set this.input.last only to be sure, that animation won't start twice
     */
    domInformUser () {
      if (this.input.last !== this.input.value) {
        this.setLastValue()
        this.tween.play().restart()
      }
    },

    /**
     * Slider is not working after the menu slided in,
     * timeout is the time of the animation + x
     */
    domSliderAfterMenuOpened () {
      window.setTimeout(() => {
        this.domUpdateSlider()
      }, 400)
    }
  },
  created () {
    // don't listen to events when input is completely disabeled
    Bus.listen('calc/on-input-value-changed', this.onInputValueChanged)
    Bus.listen('calc/on-after-input-value-changed', this.onAfterInputValueChanged)
    Bus.listen('calc/on-after-calculation', this.onAfterCalculation)
    if (!this.disabled) {
      Bus.listen('calc/on-after-door-changed', this.onAfterDoorChanged)
      Bus.listen('calc/on-after-calculation', this.onAfterCalculation)
      Bus.listen('calc/on-set-lock-dependent', this.onSetLockDependent)
    }
    Bus.listen('calc/on-mobile-input-opened', this.domSliderAfterMenuOpened)
  },
  mounted () {
    // for unknown reasons slider component throws an error if it's not done with $nextTick
    if (this.disabled) {
      this.$nextTick(this.emitInputLock)
    }
    this.tween = new TimelineLite()
    this.tween.pause()
    this.tween.to(this.$refs.input, 0.4, { color: 'transparent' })
    this.tween.to(this.$refs.input, 0.4, { color: '#ff3860' })
    this.tween.to(this.$refs.input, 0.4, { color: 'transparent' })
    this.tween.to(this.$refs.input, 0.4, { color: '#009ff5' })
    this.tween.call((input) => { input.style = '' }, [this.$refs.input]) // to make css-class work again
    this.domUpdateSlider()
  }
}
</script>

<style lang="sass">
.slider-input

  .fieldlabel
    position: relative
    min-height: $icon-small

    .lock
      position: absolute
      top: .2rem
      right: 0

  .control
    position: relative

    .input
      height: 3.2rem
      color: $info
      text-align: center
      font-size: $size-1
      font-family: $family-mono-light

      &.readonly
        background-color: $white-ter

        &:hover,
        &:focus,
        &:active
          border-color: $border

      &.error
        color: $red

    .step
      display: none

  .slider
    position: relative
    height: 3rem
    margin-top: $content-padding-mobile
    padding-top: 1.25rem

    .slider-frame
      position: absolute
      top: 7px
      left: 7px
      right: 7px
      height: 14px
      padding: 2px
      background-color: $white
      border: 1px solid $border

      .slider-bar
        width: 100%
        height: 100%
        background-color: $grey-lighter

        .vue-slider-component
          position: relative
          padding: 0px !important

          .vue-slider
            background-color: $white
            border-radius: 0px

          .vue-slider-process
            background-color: $primary
            border-radius: 0px

          .vue-slider-dot

            &:after
              position: absolute
              content: ""
              top: 2px
              left: 2px
              width: 16px
              height: 16px
              background-color: $primary
              border-radius: 8px

    .slider-measure
      padding: 0 5px
      margin: .4rem .25rem
      height: .3rem

      .tick
        position: relative
        display: block
        width: 5%
        height: .4em
        float: left

        &:after,
        &:before
          position: absolute
          content: ""
          display: block
          width: 1px
          top: .1rem
          height: 50%
          background: #5a585b
          background-image: linear-gradient(#020206, #737476)

        &:after
          margin-left: 50%

        &:first-child
          &:before
            top: 0
            height: 100%

        &:last-child
          margin-right: -1px
          width: 0

          &:after
            top: 0
            height: 100%

    .slider-boundaries
      font-size: .65rem
      font-family: $family-mono-light

      .boundary
        color: $grey

        &.max
          float: right

+tablet
  .slider-input

    .slider
      height: 4rem

+desktop

  .slider-input

    .control

      .step
        display: inline-block
        position: absolute
        right: .85rem

        &.step-up
          top: .9rem

        &.step-down
          top: 1.6rem

</style>
