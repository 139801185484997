<template>
  <nav class="mobile-menu navigation-segment" ref="navigationSegment" :class="{ 'is-visible': mobileMenuVisible }">
    <div class="container">
      <div class="toolbar">
        <icon-button
          icon="tke_icon_close"
          class="close is-white"
          @click.native="hide">
        </icon-button>
      </div>
    </div>
    <div class="container">
      <ul class="navigation">
        <li>
          <router-link :to="{ name: 'start' }" @click.native="hide">
            {{ $t('navigation.home') }}
          </router-link>
        </li>
        <li class="calculator">
          <a @click="toggleCabin">
            <span>{{ $t('navigation.cabin') }}</span>
            <span class="icon"><svg-icon name="tke_icon_select" class="is-medium"></svg-icon></span>
          </a>
          <ul v-if="mobileMenuVisibleCabin">
            <li>
              <router-link :to="{ name: 'calculator', params: { calc: 'cabin', product: 'evolution200' }}" @click.native="hide">
                {{ $t('products.evolution200') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'calculator', params: { calc: 'cabin', product: 'evolution300' }}" @click.native="hide">
                {{ $t('products.evolution300') }}
              </router-link>
            </li>
          </ul>
        </li>
        <li class="calculator">
          <a @click="toggleShaft">
            <span>{{ $t('navigation.shaft') }}</span>
            <span class="icon"><svg-icon name="tke_icon_select" class="is-medium"></svg-icon></span>
          </a>
          <ul v-if="mobileMenuVisibleShaft">
            <li>
              <router-link :to="{ name: 'calculator', params: { calc: 'shaft', product: 'evolution200' }}" @click.native="hide">
                {{ $t('products.evolution200') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'calculator', params: { calc: 'shaft', product: 'evolution300' }}" @click.native="hide">
                {{ $t('products.evolution300') }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'help' }" @click.native="hide">
            {{ $t('navigation.help') }}
          </router-link>
        </li>
        <li>
          <a :href="$t('links.contact')" target="_blank">{{ $t('navigation.contact') }}</a>
        </li>
        <li>
          <lang-select-elem></lang-select-elem>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'
import LangSelectElem from '../elements/LangSelectElem'

export default {
  name: 'navigation-segment',
  components: {
    'lang-select-elem': LangSelectElem
  },
  data () {
    return {
      mobileMenuVisible: false,
      mobileMenuVisibleCabin: false,
      mobileMenuVisibleShaft: false,
      windowWidth: null
    }
  },
  methods: {
    show () {
      this.mobileMenuVisibleCabin = (this.$route.name === 'calculator' && this.$route.params.calc === 'cabin')
      this.mobileMenuVisibleShaft = (this.$route.name === 'calculator' && this.$route.params.calc === 'shaft')
      this.mobileMenuVisible = true
      this.windowWidth = window.innerWidth
      window.addEventListener('resize', this.handleResize)
      this.$refs.navigationSegment.scrollTo(0, 0)
      Bus.fire('global/show-overlay-menu', 'global/hide-navigation-menu')
    },
    hide () {
      this.mobileMenuVisible = false
      window.removeEventListener('resize', this.handleResize)
      window.scrollTo(0, 0)
      Bus.fire('global/hide-overlay')
    },
    handleResize () {
      if (window.innerWidth !== this.windowWidth && this.mobileMenuVisible) {
        this.hide()
      }
    },
    toggleCabin () {
      this.mobileMenuVisibleCabin = !this.mobileMenuVisibleCabin
    },
    toggleShaft () {
      this.mobileMenuVisibleShaft = !this.mobileMenuVisibleShaft
    }
  },
  mounted () {
    Bus.listen('global/show-navigation-menu', this.show)
    Bus.listen('global/hide-navigation-menu', this.hide)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="sass">

.navigation-segment
  z-index: $z-index-menu + 1

  .container

    .navigation

      li
        border-top: 1px solid $grey-lighter
        font-size: $size-3
        width: 100%

        &:first-child
          border-top: none

        a
          display: inline-block
          padding: 1rem 0
          width: 100%

          &.router-link-exact-active
            color: $grey-dark
            cursor: default

          .icon
            float: right

            .svg-icon
              width: 1.2rem
              height: 1.2rem

        > ul

          li
            padding-left: 1.5rem

            &:first-child
              border-top: 1px solid $grey-lighter

+tablet

  .navigation-segment
    position: absolute
    top: 0
    right: 0
    left: 0
    height: 0
    width: 100%
    background-color: transparent
    z-index: $z-index-header + 1

    .container
      position: relative

      .navigation
        position: absolute
        top: .8rem
        right: $content-padding-desktop

        li
          float: left
          width: auto
          font-size: $size-6
          border: none

          a
            position: relative
            float: left
            display: inline-block
            color: $text-invert
            padding: .35rem .7rem

            &.router-link-exact-active, &:hover
              color: $text-invert

              &::after
                content: ""
                position: absolute
                border-top: 1px solid
                left: .7rem
                right: .7rem
                bottom: .375rem

          &:last-child
            margin-right: 0

            .button
              padding-right: 0

          &.calculator
            display: none

+desktop

  .navigation-segment

    .container

      .navigation
        top: 1rem

</style>
